<template>
  <div class="all" :style="allStyle[0]">
    <div class="top flex_r">
      <div @click="onClickLeft">
        <van-icon size="0.4rem" color="#ffffff" name="arrow-left" />
      </div>
      <div class="topTitle">健康申报卡</div>
    </div>
    <div class="card_middle">
      <div class="hosName">{{ list.hospitalName }}</div>
      <div class="name">
        申报人：<span>{{ list.applicantName }}</span>
      </div>
      <div class="otherName" v-if="list.agentName">
        代申报人：<span>{{ list.agentName }}</span>
      </div>
      <div class="time yellow" :style="allStyle[1]">
        <div class="yellowOne" :style="allStyle[2]">有效期至{{ list.expirationTime }}</div>
      </div>
      <div class="imgCard"><img :src="list.qrCode" alt="" /></div>
      <div class="isTime">当日有效</div>
      <div class="btn flex_c">
        <button class="yellow" @click="onClickLeft" :style="allStyle[1]">
          本次申报
        </button>
        <button @click="toRecord" :style="allStyle[0]">申报记录</button>
        <button @click="toWrite" :style="allStyle[0]">重新填写</button>
      </div>
    </div>
    <div class="bottom_desc">由创鑫互联提供技术支持</div>
  </div>
</template>

<script>
import { NavBar, ActionBar, Popup, ActionBarButton, Toast, Dialog } from "vant";
import api from "@/api/api/Index";
export default {
  components: {
    vanNavBar: NavBar,
    vanActionBar: ActionBar,
    vanActionBarButton: ActionBarButton,
    vanPopup: Popup,
    vanDialog: Dialog,
  },
  data() {
    return {
      allStyle: [
        {
          backgroundColor: "#EE854E",
        },
        {
          border: "0.01rem solid rgba(238, 133, 78, 0.3)",
          color: "#EE854E",
        },
        {
          border: " 0.01rem solid rgba(238, 133, 78, 0.1)",
          color: "#EE854E",
        },
      ],
      type: 3,
      list: {},
      bColor: [
        {
          type: "Yellow",
          backgroundColor: "#EE854E",
          border: "0.01rem solid rgba(238, 133, 78, 0.3)",
          border1: " 0.01rem solid rgba(238, 133, 78, 0.1)",
          color: "#EE854E",
        },
        {
          type: "Red",
          backgroundColor: "#DD4747",
          border: "0.01rem solid rgba(221, 71, 71, 0.3)",
          border1: "0.01rem solid rgba(221, 71, 71, 0.1)",
          color: "#DD4747",
        },
        {
          type: "Green",
          backgroundColor: "#2DBD8D",
          border: " 0.01rem solid rgba(0, 192, 137, 0.3)",
          border1: "0.01rem solid rgba(0, 192, 137, 0.1)",
          color: "#2DBD8D",
        },
      ],
    };
  },
  created() {
    console.log(this.$route.query.num);
    api.Question.getQrCodeState(this.$route.query.num).then((res) => {
      this.list = res;
      console.log(this.list);
      var dd = new Date();
      let month =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      let day =
        dd.getDate() + 1 < 10 ? "0" + (dd.getDate() + 1) : dd.getDate() + 1;
      let min =
        dd.getMinutes() + 1 < 10
          ? "0" + (dd.getMinutes() + 1)
          : dd.getMinutes() + 1;
      this.list.time =
        dd.getFullYear() +
        "/" +
        month +
        "/" +
        day +
        " " +
        dd.getHours() +
        ":" +
        min;
      console.log(res);
      this.bColor.forEach((element) => {
        if (element.type === res.color) {
          this.allStyle[0].backgroundColor = element.backgroundColor;
          this.allStyle[1].border = element.border;
          this.allStyle[1].color = element.backgroundColor;
          this.allStyle[2].border = element.border1;
          this.allStyle[2].color = element.backgroundColor;
        }
      });
    });
  },
  methods: {
    onClickLeft() {
      this.$router.push({
        path: "question",
        query: {
          num: this.$route.query.num,
          templateId: this.$route.query.templateId,
        },
      });
    },
    toRecord() {
      this.$router.push({
        path: "record",
        query: {
          num: this.$route.query.num,
          templateId: this.$route.query.templateId,
        },
      });
    },
    toWrite() {
      this.$router.push({
        path: "question",
        query: { templateId: this.$route.query.templateId ,reView:true,code:this.$route.query.num},
      });
    },
  },
};
</script>

<style scoped>
.all {
  padding: 0.2rem 0.4rem 0 0.4rem;
  height: 100vh;
}
.topTitle {
  font-size: 0.3rem;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 1px;
  margin: 0 auto;
}
.top {
  padding-bottom: 0.2rem;
  width: 100%;
}
.card_middle {
  width: 6.7rem;
  height: 9rem;
  background: #ffffff;
  border-radius: 0.2rem;
  text-align: center;
}
.hosName {
  padding: 0.17rem 0;
  font-size: 0.32rem;
  font-weight: 600;
  color: #333333;
  border-bottom: 0.01rem solid #eaeaea;
  margin-bottom: 0.3rem;
}
.card_middle .name {
  color: #333333;
  font-size: 0.28rem;
  margin: 0.1rem 0;
}
.card_middle .otherName {
  font-size: 0.24rem;
  font-weight: 400;
}
.card_middle .time {
  width: 4.4rem;
  height: 0.6rem;
  border-radius: 0.3rem;

  line-height: 0.6rem;
  margin: 0.2rem auto;
}
.time div {
  width: 4.26rem;
  height: 0.46rem;
  border-radius: 0.27rem;

  line-height: 0.46rem;
  margin: 0.05rem auto;
}
.yellow {
  /* border: 0.01rem solid ; */
  color: #ee854e;
}
.yellowOne {
  /* border: 0.01rem solid ; */
  font-size: 0.24rem;
  font-weight: 50;
  color: #ee854e;
  letter-spacing: 1px;
}
.imgCard {
  width: 2rem;
  height: 2.02rem;
  margin: 0 auto;
}
.isTime {
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
  color: #333333;
  font-size: 0.24rem;
}
.btn button {
  width: 4.4rem;
  height: 0.6rem;
  border-radius: 0.3rem;
  margin: 0 auto;
  margin-bottom: 0.2rem;
}
.btn button:nth-child(1) {
  background-color: #ffffff;
}
.btn button:not(:first-child) {
  color: #ffffff;
  border: none;
}
.bottom_desc {
  text-align: center;
  color: #ffffff;
  font-size: 0.24rem;
  margin-top: 3.5rem;
  font-weight: 400;
  letter-spacing: 1px;
}
</style>