import request from '@/api/axios'; // 导入http中创建的axios实例


const Login = {
    //获取当前登录用户信息
    getCurrentUser(){
        return request({
            url: '/api/open/mobile/user/getCurrentUser',
            method: 'get',
        })
    },
    //获取当前登录用户订单数
    getCurrentUserOrder(){
        return request({
            url: '/api/h5/exam/order/getCurrentUserOrder',
            method: 'get',
        })
    },
    //用户退出登录
    logout(){
        return request({
            url: '/api/open/mobile/user/logout',
            method: 'delete',
        })
    },
    //获取验证码
    getLoginCaptcha(data){
        return request({
            url: '/api/open/mobile/user/getLoginCaptcha',
            method: 'get',
            params:data
        })
    },
    //登录
    loginByCaptcha(data){
        return request({
            url: '/api/open/mobile/user/loginByCaptcha',
            method:'post',
            params:data
        })
    }
}


export default Login;