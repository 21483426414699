import { createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons-vue'  //全局图标
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import '../src/assets/css/common.css'
import '../src/assets/css/main.css'

//引入画布
import vueEsign from 'vue-esign'

// 引入自适应
import './assets/js/rem'
// import 'lib-flexible/flexible.js'

import print from 'vue3-print-nb'
import { Icon ,Toast,Dialog } from 'vant';
const app = createApp(App);
import common from './utils/utils.js';//全局js文件

app.config.globalProperties.$common=common;
app.use(store)
app.use(vueEsign)
app.use(router)
app.use(NutUI)
app.use(Icon,Toast,Dialog)
app.use(ElementPlus,{
    zIndex: 3000,
    size: 'large'
})
app.use(print)
// app.use(axiosApi)
app.mount('#app')
Object.keys(Icons).forEach((key) => {
    app.component(key, Icons[key])
  })
  

