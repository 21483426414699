<template>
  <van-nav-bar title="订单列表" left-arrow @click-left="onClickLeft" fixed>
    <template #left>
      <i
        class="van-badge__wrapper van-icon van-icon-cross"
        style="font-size: 0.4583rem; color: #2e2e2e"
      ></i>
    </template>
    <template #right>
      <router-link to="/User">
        <!-- <i
          class="van-badge__wrapper van-icon van-icon-ellipsis"
          style="font-size: 0.5208rem; color: #2e2e2e"
        ></i> -->
         <img style="width:0.4rem" src="@/assets/img/user11.png" alt="">
      </router-link>
    </template>
  </van-nav-bar>
  <van-overlay :show="show">
    <div class="wrapper">
      <van-loading type="spinner" />
    </div>
  </van-overlay>
  <div class="container pt46 pb50">
    <div class="tijian_order_wrap">
      <div class="nav br_b_1_grey">
        <ul class="hd bg_white flex_r f_ai_c f_jc_b pr22 pl22">
          <li
            v-for="(item, index) in tabList"
            :key="index"
            @click="tabSelect(item.orderState)"
          >
            <a
              class="flex_c f_ai_c pt14 pb14 cr_666 fs14"
              :class="item.checked ? 'active' : ''"
            >
              <span>{{ item.name }}</span>
              <span class="active-line dis_b brs_2 bg_org"></span>
              <i class="tc lh15 cr_fff fs12 bg_org brs50" v-if="item.num > 0">{{
                item.num
              }}</i>
            </a>
          </li>
        </ul>
      </div>
      <div class="list_content pt46">
        <ul class="bd pt10 pb10 bg_f5f">
          <li
            class="mb10 pd15 bg_white"
            v-for="(item, index) in orderList"
            :key="index"
            @click="toPage('OrderDetail', item.orderId)"
          >
            <div class="top flex_r f_ai_s f_jc_b mb15">
              <p class="cr_999 fs12">下单时间：{{ item.createTimeStamp }}</p>
              <span v-if="item.orderState == 7" class="status_color_default"
                >已取消</span
              >
              <span v-if="item.orderState != 7" class="status_color_primary">{{
                item.statusText
              }}</span>
            </div>

            <div class="content flex_r f_jc_b pb13 br_b_1_grey">
              <div class="content-left flex_r">
                <img class="mr13 brs_5" :src="item.imgUrl" />
                <div class="center">
                  <h3 class="fs13 fw600 mb22 cr_000">{{ item.mealName }}</h3>
                  <span class="price2">
                    ￥
                    <b>{{ item.orderAmount }}</b>
                  </span>
                </div>
              </div>
              <div class="num fs12 cr_888">x<span>1</span></div>
            </div>

            <div class="price" v-if="item.orderState == 0">
              需付：<span class="fs13"
                >￥<b>{{ item.orderAmount }}</b></span
              >
            </div>

            <div class="bottom flex_r f_jc_b f_ai_c pt10">
              <div class="djs fs12 cr_999">
                <span v-if="item.orderState == 0">{{ item.remainTime }}</span>
              </div>
              <div>
                <a
                  v-if="item.orderState == 7"
                  @click.stop="deleteOrder(item)"
                  class="btn_order order_delete"
                >
                  删除订单
                </a>
                <a
                  v-if="
                    item.orderState == 3 ||
                    item.orderState == 4 ||
                    item.orderState == 5 ||
                    item.orderState == 6 ||
                    item.orderState == 7
                  "
                  @click.stop="toPage('ProjectDetail', item.mealId)"
                  class="btn_order btn_default"
                >
                  再次购买
                </a>
                <a
                  v-if="
                    item.orderState == 0 ||
                    item.orderState == 1 ||
                    item.orderState == 2
                  "
                  @click.stop="cancelOrder(item)"
                  class="btn_order order_delete"
                >
                  取消订单
                </a>
                <a v-if="item.orderState == 0" class="btn_order btn_warn">
                  立即支付
                </a>
                <a v-if="item.orderState == 1" class="btn_order btn_warn">
                  立即预约
                </a>
                <a
                  v-if="item.orderState == 2"
                  class="btn_order btn_hint"
                  @click.stop="toPage('Inform')"
                >
                  体检须知
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import "vant/lib/index.css";
import { ref, getCurrentInstance } from "vue";
import api from "@/api/api/Index";
import { Dialog, NavBar, Loading, Overlay } from "vant";
import { useStore } from "vuex";
export default {
  components: {
    vanNavBar: NavBar,
    vanDialog: Dialog,
    vanOverlay: Overlay,
    vanLoading: Loading,
  },
  setup() {

    const active = ref(0);
    const store = new useStore();
    const { proxy } = getCurrentInstance();
    return {
      active,
      store,
      proxy,
    };
  },
  created() {
    this.getOrderList();
  },
  methods: {
       onClickLeft(){
            this.$router.push({path:"/user"})
        },
    getOrderList() {
      var _this = this;
      api.Login.getCurrentUser().then((res) => {
        _this.tabList = [
          {
            name: "全部",
            checked: false,
            num: 0,
            orderState: null,
            list: null,
          },
          {
            name: "待付款",
            checked: false,
            num: res.awaitPay,
            orderState: 0,
            list: null,
          },
          {
            name: "待预约",
            checked: false,
            num: res.awaitSubscribe,
            orderState: 1,
            list: null,
          },
          {
            name: "待体检",
            checked: false,
            num: res.awaitPhysicalExam,
            orderState: 2,
            list: null,
          },
          {
            name: "退款",
            checked: false,
            num: res.refund,
            orderState: 3,
            list: null,
          },
          {
            name: "已体检",
            checked: false,
            num: res.haveAMedical,
            orderState: 6,
            list: null,
          },
        ];
        this.userId = res.userId;
        if (_this.$route.query.type != "") {
          api.OrderList.OrderList({
            orderState: _this.$route.query.type,
            userId: this.userId,
          }).then((res) => {
            _this.orderList = res.rows;
            _this.getOrderData(_this.orderList);
            this.tabList.forEach((element) => {
              if (element.orderState == _this.$route.query.type) {
                element.checked = true;
              }
            });
          });
        }
      });
    },
    getOrderData(value) {
      //订单状态  0:待付款 1:待预约 2:待体检 3:审核中 4:已退款 5:审核未通过 6:已体检 7:取消
      var statusText = [
        "待付款",
        "待预约",
        "待体检",
        "审核中",
        "已退款",
        "审核未通过",
        "已体检",
        "取消",
        "体检中"
      ];
      var _this = this;
      value.forEach((item, index) => {
        console.log(item);
        console.log(1111);
        if (item.orderState == 0) {
          item.interval = "";
          //设置订单失效时间
          var time = item.createTimeStamp * 1000 + 1000 * 60 * 60 * 2;
          var currentTime = new Date().getTime();
          var cm = time - currentTime;
          if (cm <= 1000 * 60 * 60 * 2 && cm > 0) {
            item.remainTime = _this.proxy.$common.toHHmmss(cm);
            _this.proxy.$common.setTimer(cm, item);
          }
        }
        item.createTimeStamp = _this.proxy.$common.format(item.createTimeStamp * 1000);
        item.statusText = statusText[item.orderState];
      });
    },
    deleteOrder(val) {
      var id = val.orderId;
      var message = "是否删除该订单";
      Dialog.confirm({
        title: "",
        message: message,
      })
        .then(() => {
          // on confirm
          var i;
          var _this = this;
          api.OrderList.deleteOrder(id).then((res) => {
            _this.orderList.forEach((item, index) => {
              if (item.orderId == id) {
                i = index;
              }
              if (item.status == 1) clearInterval(item.interval);
            });
            _this.orderList.splice(i, 1);
            _this.tabList.forEach((item, index) => {
              if (
                item.orderState == _this.$route.query.type &&
                _this.$route.query.type != undefined
              ) {
                item.num = _this.orderList.length;
              }
            });
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    cancelOrder(val) {
      var id = val.orderId;
      var data = {};
      data.orderId = id;
      var message = "是否取消该订单";
      if (val.orderState != 0) {
        message = "是否取消该订单,并申请退款。";
      }
      Dialog.confirm({
        title: "",
        message: message,
      })
        .then(() => {
          // on confirm
          var _this = this;
          switch (val.orderState) {
            case 0:
              data.type = 0;
              api.OrderList.cancelOrder(data).then((res) => {
                // _this.orderList.orderState = 7;
                this.getOrderList();
              });
              break;
            case 1:
              data.type = 2;
              api.OrderList.refund({
                orderid: val.orderSn,
                totalfee: val.orderAmount,
                refundfee: val.orderAmount,
              }).then((res) => {
                if (res.msg) {
                  this.$message({
                    type: "warning",
                    message: res.msg,
                  });
                } else {
                  this.show = true;
                  var num = 0;
                  var interval = setInterval(() => {
                    if (num <= 10) {
                      num++;
                      api.OrderList.getRefundStatus(val.orderSn).then((res) => {
                        if (res == 4) {
                          this.show = false;
                          this.$message({
                            type: "success",
                            message: "退款成功!",
                          });
                          this.open = false;
                          this.getOrderList();
                          clearInterval(interval);
                        }
                      });
                    } else if (num == 10) {
                      num++;
                    } else {
                      this.$message({
                        type: "warning",
                        message: "网络请求超时，请手动刷新页面",
                      });
                      this.show = false;
                      clearInterval(interval);
                      this.getOrderList();
                    }
                  }, 1000);
                }
              });
              break;
            case 2:
              data.type = 1;
              api.OrderList.cancelOrder(data).then((res) => {
                // _this.orderList.orderState = 3;
                this.getOrderList();
              });

              break;
            default:
              break;
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    tabSelect(s) {
      var _this = this;
      this.tabList.forEach((item, index) => {
        if (item.orderState === s) {
          item.checked = true;
          if (!item.list) {
            api.OrderList.OrderList({
              orderState: s,
              userId: _this.userId,
            }).then((res) => {
              _this.orderList = res.rows;
              _this.getOrderData(_this.orderList);
              item.list = res.rows;
            });
          } else {
            _this.orderList = item.list;
          }
        } else {
          item.checked = false;
        }
      });
      const { query } = this.$route.query;
      if (s == null) {
        this.$router.replace({
          query: { query },
        });
      } else {
        this.$router.replace({
          query: { query, type: s },
        });
      }
    },
    toUser() {
      this.$router.push({
        name: "User",
      });
    },
    toPage(page, id) {
      this.$router.push({
        name: page,
        query: { id: id, type: this.$route.query.type },
      });
    },
  },
  data() {
    return {
      show: false,
      userId: null,
      intervalNum: 0,
      intervalList: [],
      tabList: [],
      orderList: [],
    };
  },
};
</script>

<style scoped>
.tijian_order_wrap .nav {
  position: fixed;
  width: 100%;
  top: 0.8rem;
}
.tijian_order_wrap .hd a {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-bottom: 0.04rem solid white;
}
.tijian_order_wrap .hd a.active {
  font-size: 0.32rem;
  color: #000000;
}
.tijian_order_wrap .hd a.active .active-line {
  width: 0.32rem;
  height: 0.06rem;
}
.tijian_order_wrap .hd i {
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  top: 0.24rem;
  right: -0.28rem;
}
.tijian_order_wrap .bd .content img {
  width: 1.3rem;
  height: 1.3rem;
}
.tijian_order_wrap .bd li:last-child {
  margin-bottom: 0;
}
.tijian_order_wrap .bd .center {
  flex: 1;
}
.tijian_order_wrap .bd .bottom .btn_order {
  margin-left: 0.28rem;
}
.tijian_order_wrap .bd .price {
  text-align: right;
  color: #000000;
  font-size: 0.28rem;
  font-weight: 400;
  margin-top: 0.28rem;
}
.tijian_order_wrap .bd .price span {
  color: #f86565;
  font-size: 0.24rem;
}
.tijian_order_wrap .bd .price b {
  font-size: 0.32rem;
  font-weight: normal;
}
.btn_order {
  display: inline-block;
  width: 1.8rem;
  height: 0.6rem;
  text-align: center;
  line-height: 0.58rem;
  border-radius: 0.4rem;
  font-size: 0.24rem;
  border: solid 0.0208rem #d7d7d7;
  color: #999999;
}
.btn_default {
  border: solid 0.0208rem #d7d7d7;
  color: #444444;
}
.btn_warn {
  border: none;
  color: #ffffff;
  background: var(--otherColor);
}
.btn_hint {
  border: solid 0.0208rem #ffb98c;
  color: var(--otherColor);
  background: #fff5ef;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
