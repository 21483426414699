import axios from 'axios'; // 引入axios
import router from '../router';
import {
    ElMessage,
    ElMessageBox
} from 'element-plus'
import {
    showLoading,
    hideLoading
} from '@/utils/loading.js'
const {
    baseUrl,
    baseUrl2,
    hideloading
} = require('./config')
const service = axios.create({
    baseURL: baseUrl, // url = base api url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 1000 * 12 // 请求超时
})
// 设置post请求头
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';



// 先导入vuex,因为我们要使用到里面的状态对象
import store from '@/store/index';
import {
    Dialog
} from 'vant';
// 请求拦截器
service.interceptors.request.use(
    config => {
        config.headers.subMchId = '1522453037177327616';

        // 请求是是否开启loading
        if (!hideloading) {
            showLoading()
        }
        // 断网提示
        if (!navigator.onLine) {
            Dialog.alert({
                message: '您的网络故障，请检查!',
            }).then(() => {
                // on close
            });
            // ElMessageBox.alert(
            //   '您的网络故障，请检查!',
            //   '温馨提示',
            //   {
            //     confirmButtonText: '好的',
            //     type: 'warning'
            //   }
            // )
        }

        // 每次发送请求之前判断vuex中是否存在token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        if (store.state.token && !config.requestBase) {
            config.headers.Authorization = store.state.token;

        }

        if (config.requestBase == 'baseUrl2') {
            config.baseURL = baseUrl2;
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)



// 响应拦截器
service.interceptors.response.use(
    response => {
        // 响应拦截进来隐藏loading效果，此处采用延时处理是合并loading请求效果，避免多次请求loading关闭又开启
        setTimeout(() => {
            hideLoading()
        }, 100)
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status == 200) {
            if (response.data.code != 200) {
                switch (response.data.code) {
                    // 401: 未登录
                    // 未登录则跳转登录页面，并携带当前页面的路径
                    // 在登录成功后返回当前页面，这一步需要在登录页操作。
                    case 401:
                        router.replace({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.value.fullPath
                            }
                        });
                        break;

                    // 403 token过期
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面

                    case 403:
                        // 清除token
                        store.state.token = null;
                        localStorage.removeItem('token');
                        Dialog.confirm({
                            message: '登录过期，请重新登录',
                        })
                            .then(() => {
                                // 确认跳转登录页
                                router.replace({
                                    path: '/login',
                                    query: {
                                        redirect: router.currentRoute.value.fullPath
                                    }
                                })
                            })
                            .catch(() => {
                                // 取消返回主页
                                router.replace({
                                    path: '/',
                                    query: {
                                        redirect: router.currentRoute.value.fullPath
                                    }
                                })
                            })


                        break;

                    // // 500请求不存在
                    case 500:
                        console.log(response.data.msg);
                        if (response.data.msg.includes('获取用户信息异常')) {
                            Dialog.alert({
                                message: '请先登录',
                            })
                                .then(() => {
                                    // 确认跳转登录页
                                    router.replace({
                                        path: '/login',
                                        query: {
                                            redirect: router.currentRoute.value.fullPath
                                        }
                                    })
                                })
                        } else {
                            Dialog.alert({
                                message: response.data.msg,
                            })
                        }

                        break;
                    // // 404请求不存在
                    case 404:
                        Dialog.alert({
                            message: '网络请求不存在',
                        })
                        // ElMessageBox.alert(
                        //   '网络请求不存在',
                        //   {
                        //     confirmButtonText: '好的',
                        //     type: 'warning'
                        //   });
                        break;
                    // 其他错误，直接抛出错误提示
                    default:
                        Dialog.alert({
                            message: response.data.msg,
                        })
                    // ElMessageBox.alert(
                    //   response.data.msg,
                    // );
                }
                return Promise.reject(response.data.data || response.data);
            }
            return Promise.resolve(response.data.data || response.data);

        } else {

            return Promise.reject(response.data.data || response.data);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        setTimeout(() => {
            hideLoading()
        }, 100)
        // if (error.response.status) {
        switch (error.response.status) {
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
            case 401:
                router.replace({
                    path: '/login',
                    query: {
                        redirect: router.currentRoute.value.fullPath
                    }
                });

                break;

            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面

            case 403:
                // 清除token
                store.state.token = null;
                localStorage.removeItem('token');
                Dialog.confirm({
                    message: '登录过期，请重新登录',
                })
                    .then(() => {
                        // 确认跳转登录页
                        router.replace({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.value.fullPath
                            }
                        })
                    })
                    .catch(() => {
                        // 取消返回主页
                        router.replace({
                            path: '/',
                            query: {
                                redirect: router.currentRoute.value.fullPath
                            }
                        })
                    })


                break;

            // 404请求不存在
            case 404:
                Dialog.alert({
                    message: '网络请求不存在',
                })
                // ElMessageBox.alert(
                //   '网络请求不存在',
                // );
                break;
            // 其他错误，直接抛出错误提示
            default:
                Dialog.alert({
                    message: error.response.data.message,
                })
            // ElMessageBox.alert(
            //   error.response.data.message,
            // );
        }
        return Promise.reject(error.response);
        // }else {
        //     // 处理断网的情况
        //     // eg:请求超时或断网时，更新state的network状态
        //     // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
        //     // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
        //     store.commit('changeNetwork', false);
        // }


    });

//最后导出实例
export default service;