<template>
    <van-nav-bar style="z-index: 100" :title="title" left-arrow @click-left="onClickLeft" fixed v-if="notShowBtn == false">
        <template #left>
            <i class="van-badge__wrapper van-icon van-icon-cross" style="font-size: 0.4583rem; color: #2e2e2e"></i>
        </template>
        <template #right>
            <router-link to="/User">
                <i class="van-badge__wrapper van-icon van-icon-ellipsis" style="font-size: 0.5208rem; color: #2e2e2e"></i>
            </router-link>
        </template>
    </van-nav-bar>
    <van-nav-bar style="z-index: 100; background: #cccccc" title="预览模式" color="#cccccc" fixed v-if="notShowBtn">
    </van-nav-bar>
    <div class="all pt43">
        <div class="top posf bg_fff w100" style="z-index: 100">
            <div class="topBox flex_r pl">
                <div class="left">
                    <div class="img">
                        <img v-if="hosData.picture" :src="hosData.picture" alt="" />
                    </div>
                </div>
                <div class="right pd5 flex_c f_jc_b">
                    <div class="hosName">{{ hosData.name }}</div>
                    <div class="top_middle flex_r f_ai_c">
                        <img src="@/assets/img/index/phone.png" alt="" />
                        <div class="phone">{{ hosData.telephone }}</div>
                    </div>
                    <div class="address flex_r f_ai_c">
                        <img src="@/assets/img/index/address.png" alt="" />
                        <div>{{ hosData.address }}</div>
                    </div>
                </div>
            </div>
            <div class="bottomBox">
                为做好新冠肺炎防治工作，请您配合我们做好流行病学史调查！
            </div>
        </div>
        <div class="middle">
            <div class="content">
                <div v-for="(item, index) in questionVoList" :key="index">
                    <div class="twoSelect bg_fff" v-if="item.type == 8">
                        <el-radio v-model="item.answer" v-for="(item1, index) in item.optionVoList" :key="index" @click="translate" @change="toChange(item, item1)" :label="item1.id" :disabled="item.disabled">{{ item1.name }}</el-radio>
                    </div>
                    <div class="basicInfo">
                        <div class="formBox">
                            <el-form :model="form" ref="form" :rules="rules">
                                <div class="investInfo">
                                    <!-- 单选题 -->
                                    <el-form-item v-if="item.type == 0 &&item.fontType.InSubSort == 0 &&item.isShow == true" :label="item.fontType.index + '、' + item.question" :required="item.required">
                                        <el-radio v-model="item.answer" v-for="(item1, index) in item.optionVoList" :key="index" :label="item1.id" @change="toChange(item, item1)" :disabled="item.disabled">
                                            <span>{{ item1.name }}</span>
                                            <input type="text" name="options" v-if="item1.readonly" class="other" v-model="item1.aliasName" />
                                        </el-radio>
                                    </el-form-item>
                                    <!-- 分段标记 -->
                                    <div v-if="partList.length > 0 &&item.type == 4 &&item.isShow == true">
                                        <el-form-item v-for="(list, listIndex) in partList" :key="listIndex" :label="list.question" :required="item.required" v-show="item.question == list.question">
                                            <ul>
                                                <li v-for="(littleList, littleListIndex) in list.arr" :key="littleListIndex">
                                                    <!-- 多选题 -->

                                                    <el-form-item v-if="littleList.type == 1 && littleList.isShow == true" :label="littleList.fontType.index +'、' +littleList.question" :required="littleList.isMandatory">
                                                        <el-checkbox v-model="opts.checked" :disabled="littleList.disabled" v-for="(opts, optsIndex) in littleList.optionVoList" :key="optsIndex" :label="opts.name" name="type" @change="toChange(littleList, opts)">
                                                            <span>{{ opts.name }}</span>
                                                            <input type="text" name="options" v-if="opts.readonly" class="other" :disabled="littleList.disabled" v-model="opts.aliasName" />
                                                        </el-checkbox>
                                                    </el-form-item>

                                                    <!-- 单选题 -->
                                                    <el-form-item v-if="littleList.type == 0 && littleList.isShow == true" :label="littleList.fontType.index +'、' +littleList.question" :required="littleList.isMandatory">

                                                        <el-radio v-model="littleList.answer" v-for="(opts, optsIndex) in littleList.optionVoList" :disabled="littleList.disabled" :key="optsIndex" :label="opts.id" @change="toChange(littleList, opts)">
                                                            <span>{{ opts.name }}</span>
                                                            <input type="text" name="options" v-if="opts.readonly" class="other" :disabled="littleList.disabled" v-model="opts.aliasName" />
                                                        </el-radio>
                                                    </el-form-item>
                                                    <!-- 填空题 -->
                                                    <div v-if="littleList.type == 2 && littleList.isShow == true">
                                                        <el-form-item :label="littleList.fontType.index +'、' +littleList.question" :required="littleList.isMandatory">
                                                            <el-input :disabled="littleList.disabled" v-model="littleList.answer"></el-input>
                                                        </el-form-item>
                                                    </div>
                                                    <!-- 下拉题 -->
                                                    <div v-if="littleList.type == 3 && littleList.isShow == true" class="downQues">
                                                        <el-form-item :label="littleList.fontType.index +'、' +littleList.question" :required="littleList.isMandatory">

                                                            <el-select v-model="littleList.answer" placeholder="请选择答案" :disabled="littleList.disabled" @change="toChange(littleList, $event)">
                                                                <el-option v-for="(opts, index) in littleList.optionVoList" :key="index" :label="opts.name" :value="opts.id"></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                    </div>
                                                    <!-- 签名 -->
                                                    <div v-if="littleList.type == 5 && item.isShow == true" style="margin-top: 0.6rem">
                                                        <div class="showBox showBox1" @click="showSign()">
                                                            填写人签名:<span v-show="form.resultImg == ''">（点击图片可重签）</span>

                                                            <div style="width: 1.3rem; height: 0.8rem">
                                                                <img style="width: 1.3rem;height: 0.5rem;text-align: center;margin-left: 1rem;" @click="showSign()" v-show="form.resultImg != ''" :src="form.resultImg" alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </el-form-item>
                                    </div>

                                    <!-- 多选题 -->
                                    <div class="problem">
                                        <el-form-item v-if="item.type == 1 && item.fontType.InSubSort == 0 && item.isShow == true" :label="item.fontType.index + '、' + item.question" required>
                                            <el-checkbox v-model="item1.checked" :disabled="item.disabled" v-for="(item1, index) in item.optionVoList" :key="index" :label="item1.name" @change="toChange(item, item1)" name="type">
                                                <span> {{ item1.name }}</span>
                                                <input type="text" name="options" v-if="item1.readonly" class="other" readonly v-model="item1.aliasName" />
                                            </el-checkbox>
                                        </el-form-item>
                                    </div>
                                    <!-- 填空题 -->
                                    <div class="blank" v-if="
                      item.type == 2 &&
                      item.fontType.InSubSort == 0 &&
                      item.isShow == true
                    ">
                                        <el-form-item :label="item.fontType.index + '、' + item.question" required>
                                            <el-input :disabled="item.disabled" v-model="item.answer"></el-input>
                                        </el-form-item>
                                    </div>
                                    <!-- 下拉题 -->
                                    <div class="downQues" v-if="
                      item.type == 3 &&
                      item.fontType.InSubSort == 0 &&
                      item.isShow == true
                    ">
                                        <el-form-item :label="item.fontType.index + '、' + item.question" required>
                                            <el-select v-model="item.answer" placeholder="请选择答案" @change="toChange(item,$event)">
                                                <el-option :disabled="item.disabled" v-for="(item1, index) in item.optionVoList" :key="index" :label="item1.name" :value="item1.id"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <!-- 签名 -->
                                    <div class="showBox" @click="showSign()" v-if="item.type == 5 && item.isShow == true">
                                        填写人签名:<span v-show="form.resultImg == ''">（点击图片可重签）</span>

                                        <div style="width: 1.3rem; height: 0.8rem">
                                            <img style="
                          width: 1.3rem;
                          height: 0.5rem;
                          text-align: center;
                          margin-left: 1rem;
                          z-index: 1000;
                        " @click="showSign()" v-show="form.resultImg != ''" :src="form.resultImg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                    </div>
                </div>
                <div class="bottom_box">
                    <div class="belive">
                        <div>
                            <el-checkbox v-model="checked" shape="square" name="rule" id="rule" required :rules="[{ required: true }]" @change="isToSumbit">本人承诺对上述信息内容的真实性和完整性负责。如果信息不实或缺失引起疫情传播和扩散，本人愿意承担由此带来的全部法律责任。</el-checkbox>
                        </div>

                    </div>
                </div>
            </div>
            <div class="centerBox pb44">
                <div class="lastBox">由创鑫互联提供技术支持</div>
            </div>
        </div>
    </div>
    <van-action-bar v-if="showBtn">
        <button class="sumbitBtn" :class="showSubmit ? 'activeSumbit' : 'sumbitBtn'" @click="sumbitForm()">
            提交，获取健康申报卡
        </button>
    </van-action-bar>
    <van-popup class="signUp" v-model:show="show" position="bottom">
        <div class="popAll">
            <div class="popTop flex_r f_jc_b f_ai_c">
                <div @click="show = false">
                    <van-icon name="cross" size="0.4rem" />
                </div>
                <div>请在下方签名</div>
                <div></div>
            </div>
            <div style="background-color: #f1f1f1">
                <vue-esign class="signIn mauto" ref="esign" :isCrop="isCrop" :lineWidth="lineWidth" :height="height" lineColor="#000000" />
            </div>
            <div class="btn">
                <button @click="handleReset">清除</button>
                <button @click="handleGenerate">确定</button>
            </div>
        </div>
    </van-popup>
    <van-dialog class="dia" v-if="dialog == true" title="请将信息填写完整！" confirmButtonColor="#333333" confirmButtonText="取消" width="200">
    </van-dialog>
</template>

<script>
import axios from "axios";
import {
    NavBar,
    ActionBar,
    Popup,
    ActionBarButton,
    Toast,
    Dialog,
    Checkbox,
} from "vant";
import api from "@/api/api/Index";
export default {
    components: {
        vanNavBar: NavBar,
        vanActionBar: ActionBar,
        vanActionBarButton: ActionBarButton,
        vanPopup: Popup,
        vanDialog: Dialog,
        vanCheckbox: Checkbox,
    },
    data() {
        return {
            title: "",
            hosData: {}, //医院信息
            bgColor: "#ffffff",
            lineWidth: "10",
            height: "440",
            show: false,
            radio: 1,
            questionVoList: [],
            answerEntityList: [],
            form: {
                name: "", //申报人姓名
                phone: "", //申报人手机号
                otherPhone: "", //待申报人手机号
                otherName: "", //待申报人姓名
                sex: 1, //待申报人性别
                peopleType: 1, //待申报人类型
                otherPeople: "", //陪同人
                manyName: "", //陪同就诊人姓名
                class: "", //所在科室
                warm: 1, //体温
                region: ["都没有"], //到达地区
                patient: ["都没有"], //接触患者地区
                city: ["都没有"], //从哪个地区来,
                symptom: ["都没有"], //症状
                danger: ["都不是"], //高风险岗位,
                identical: 1, //申报情况,
                resultImg: "",
                blank: "", //填空题
                down: "", //下拉题
            },
            dialog: false, //信息提示弹框
            checked: false, //协议
            partList: [], //逻辑数组
            showSubmit: false,
            showBtn: true,
            hospitalId: "",
            notShowBtn: false, //是否为预览
            submitFlag: true, //防止重复提交
        };
    },
    created() {
        var type = window.location.search;
        console.log(type);
        // preType: 0 预览模板 1正式模板
        if (type.includes("?preType=0") || type.includes("?preType=1")) {
            this.notShowBtn = true;
            this.showBtn = false;
        } else {
            this.showBtn = true;
        }
        if (type.includes("?preType=1")) {
            if (this.$route.query.num) {
                this.getAnswer();
            } else {
                this.getPreList(this.$route.query.templateId);
            }
        } else {
            if (this.$route.query.num) {
                this.getAnswer();
            } else {
                //是否有申报记录;
                if (!this.$route.query.reView) {
                    api.Home.isDeclare(this.$route.query.templateId).then(
                        (res) => {
                            if (res.code != 200) {
                                this.$router.push({
                                    path: "/card",
                                    query: {
                                        num: res.code,
                                        templateId:
                                            this.$route.query.templateId,
                                    },
                                });
                            }
                        }
                    );
                }
                this.getList(this.$route.query.templateId);
            }
        }
    },
    methods: {
        isToSumbit() {
            if (this.checked == false) {
                this.showSubmit = false;
            } else {
                this.showSubmit = true;
            }
        },
        //隐藏设置
        toChange(item, item1) {
            console.log(item, item1);
            var jumpSortList = [];
            this.partList.forEach((element) => {
                element.arr.forEach((element2) => {
                    element2.isShow = !element2.related;
                    element2.optionVoList.forEach((element3) => {
                        //多选的情况
                        if (element2.type == 1) {
                            if (element3.checked && element3.jumpSort)
                                jumpSortList = jumpSortList.concat(
                                    element3.jumpSort
                                );
                        } else {
                            //单选和下拉的情况
                            if (
                                element2.answer == element3.id &&
                                element3.jumpSort
                            )
                                jumpSortList = jumpSortList.concat(
                                    element3.jumpSort
                                );
                        }
                    });
                });
            });
            console.log(jumpSortList);
            if (item.type == 1) {
                if (item1.checked)
                    jumpSortList = jumpSortList.concat(item1.jumpSort);
            } else {
                jumpSortList = jumpSortList.concat(item1.jumpSort);
            }
            this.partList.forEach((element) => {
                element.arr.forEach((element2) => {
                    if (jumpSortList) {
                        jumpSortList.forEach((element3) => {
                            if (element2.sort == element3) {
                                element2.isShow = true;
                            }
                        });
                    }
                });
            });
            console.log(this.questionVoList);
            console.log(this.partList);
        },
        //预览模板
        getPreList(templateId) {
            var list = [];
            api.Question.getPreviewByiId(templateId)
                .then((res) => {
                    console.log(res);
                    this.title = res.name;
                    if (res.hospitalVo == null) {
                        this.hosData = {
                            picture: "",
                            name: "",
                            telephone: "",
                            address: "",
                        };
                    } else {
                        this.hosData = res.hospitalVo;
                    }
                    this.hospitalId = res.hospitalId;

                    res.questionVoList.forEach((element) => {
                        element.optionVoList.forEach((element2) => {
                            if (element2.jumpSort != null) {
                                element2.jumpSort =
                                    element2.jumpSort.split(",");
                            }
                        });
                    });
                    list = res.questionVoList;
                    console.log(list);
                })
                .then(() => {
                    list.forEach((element) => {
                        element.disabled = false;
                        if (element.isMandatory == 1) {
                            element.isMandatory = true;
                        } else if (element.isMandatory == 0) {
                            element.isMandatory = false;
                        }
                        if (element.type == 1) {
                            if (element.answer == null) {
                                element.answer = [];
                            }

                            element.optionId = element.id;
                        } else {
                            if (element.answer == null) {
                                element.answer = "";
                            }
                            element.optionId = element.id;
                        }
                        var arr = [];
                        var question = "";
                        element.isShow = !element.related;
                        if (element.subsectionSort) {
                            list.forEach((element2) => {
                                if (
                                    element.subsectionSort ==
                                    JSON.parse(element2.fontType).InSubSort
                                ) {
                                    question = element.question;
                                    arr.push(element2);
                                }
                            });
                        }
                        if (arr.length > 0) {
                            this.partList.push({
                                question: question,
                                arr: arr,
                            });
                        }
                    });
                    list.forEach((element) => {
                        element.fontType = JSON.parse(element.fontType);
                        list.forEach((element3) => {
                            element3.optionVoList.forEach((element2) => {
                                if (element2.jumpSort) {
                                    element2.jumpSort.forEach((jump) => {
                                        if (jump == element.sort.toString()) {
                                            console.log(element);
                                            element.isShow = false;
                                        }
                                    });
                                }
                            });
                        });
                        if (
                            element.fontType.InSubSort == 0 ||
                            element.type == 4 ||
                            element.type == 8
                        ) {
                            this.questionVoList.push(element);
                        }
                    });
                });
        },
        //正式模板
        getList(templateId) {
            // this.questionVoList = [];
            var list = [];
            api.Question.questionDetail(templateId)
                .then((res) => {
                    console.log(res);
                    this.title = res.name;
                    if (res.hospitalVo == null) {
                        this.hosData = {
                            picture: "111",
                            name: "",
                            telephone: "",
                            address: "",
                        };
                    } else {
                        this.hosData = res.hospitalVo;
                    }
                    this.hospitalId = res.hospitalId;
                    res.questionVoList.forEach((element) => {
                        //分隔逻辑跳转
                        element.optionVoList.forEach((element2) => {
                            if (element2.jumpSort) {
                                element2.jumpSort =
                                    element2.jumpSort.split(",");
                            }
                        });
                    });
                    list = res.questionVoList;
                })
                .then(() => {
                    list.forEach((element) => {
                        element.disabled = false;
                         //本人申报默认选中
                        if (element.type == 8) {
                            element.answer = element.optionVoList[0].id;
                        } 
                        //是否必填
                        if (element.isMandatory == 1) {
                            element.isMandatory = true;
                        } else if (element.isMandatory == 0) {
                            element.isMandatory = false;
                        }
                        //多选题
                        if (element.type == 1) {
                            if (element.answer == null) {
                                element.answer = [];
                            }
                            element.optionId = element.id;
                        } else {
                            if (element.answer == null) {
                                element.answer = "";
                            }
                            element.optionId = element.id;
                        }
                        var arr = [];
                        var question = "";
                        //逻辑隐藏
                        element.isShow = !element.related;
                        if (element.subsectionSort) {
                            list.forEach((element2) => {
                                if (
                                    element.subsectionSort ==
                                    JSON.parse(element2.fontType).InSubSort
                                ) {
                                    question = element.question;
                                    arr.push(element2);
                                }
                            });
                        }
                        //逻辑数组
                        if (arr.length > 0) {
                            this.partList.push({
                                question: question,
                                arr: arr,
                            });
                        }
                    });
                    list.forEach((element) => {
                        element.fontType = JSON.parse(element.fontType);
                        list.forEach((element3) => {
                            element3.optionVoList.forEach((element2) => {
                                if (element2.jumpSort) {
                                    element2.jumpSort.forEach((jump) => {
                                        if (jump == element.sort.toString()) {
                                            console.log(element);
                                            element.isShow = false;
                                        }
                                    });
                                }
                            });
                        });
                        if (
                            element.fontType.InSubSort == 0 ||
                            element.type == 4 ||
                            element.type == 8
                        ) {
                            this.questionVoList.push(element);
                        }
                    });
                });

            console.log(this.partList);
            console.log(this.questionVoList);
        },
        //获取答案
        getAnswer() {
            var list = [];
            api.Question.answer(this.$route.query.num)
                .then((res) => {
                    console.log(res);
                    this.title = res.name;
                    if (res.hospitalVo == null) {
                        this.hosData = {
                            picture: "111",
                            name: "",
                            telephone: "",
                            address: "",
                        };
                    } else {
                        this.hosData = res.hospitalVo;
                    }
                    this.hospitalId = res.hospitalId;
                    res.questionVoList.forEach((element) => {
                        element.optionVoList.forEach((element2) => {
                            if (element2.jumpSort) {
                                element2.jumpSort =
                                    element2.jumpSort.split(",");
                            }
                        });
                    });
                    list = res.questionVoList;
                    console.log(list);
                })
                .then(() => {
                    var jumpSortList = [];
                    list.forEach((element) => {
                        this.checked = true;
                        this.showSubmit = true;
                        element.disabled = true;
                        if (element.type == 5) {
                            // this.readTestFile(element.answer);
                            this.form.resultImg =
                                element.optionVoList[0].answer;
                        }

                        element.optionVoList.forEach((element2) => {
                            if (element.type == 1) {
                                if (element2.answer) element2.checked = true;
                            } else {
                                if (element2.answer)
                                    element.answer = element2.id;
                            }
                        });
                        if (element.type == 2) {
                            element.answer = element.optionVoList[0].answer;
                        }
                        var arr = [];
                        var question = "";
                        element.isShow = !element.related;
                        //处理选择关联隐藏项
                        
                        element.optionVoList.forEach((element2) => {
                            //多选的情况
                            if (element.type == 1) {
                                if (element2.checked && element2.jumpSort)
                                    jumpSortList = jumpSortList.concat(
                                        element2.jumpSort
                                    );
                            } else {
                                //单选和下拉的情况
                                if (
                                    element.answer == element2.id &&
                                    element2.jumpSort
                                )
                                    jumpSortList = jumpSortList.concat(
                                        element2.jumpSort
                                    );
                            }
                        });

                        if (element.subsectionSort) {
                            list.forEach((element2) => {
                                if (
                                    element.subsectionSort ==
                                    JSON.parse(element2.fontType).InSubSort
                                ) {
                                    question = element.question;
                                    arr.push(element2);
                                }
                            });
                        }
                        if (arr.length > 0) {
                            this.partList.push({
                                question: question,
                                arr: arr,
                            });
                        }
                    });
                    list.forEach((element) => {
                        if (jumpSortList.length > 0) {
                            jumpSortList.forEach((element2) => {
                                if (element.sort == element2) {
                                    element.isShow = true;
                                }
                            });
                        }
                    });
                    list.forEach((element) => {
                        element.fontType = JSON.parse(element.fontType);
                        list.forEach((element3) => {
                            element3.optionVoList.forEach((element2) => {
                                if (
                                    element2.jumpSort != null &&
                                    element2.name == element3.answer
                                ) {
                                    element2.jumpSort.forEach((jump) => {
                                        if (jump == element.sort.toString()) {
                                            element.isShow = true;
                                        }
                                    });
                                }
                            });
                        });
                        if (
                            element.fontType.InSubSort == 0 ||
                            element.type == 4 ||
                            element.type == 8
                        ) {
                            this.questionVoList.push(element);
                        }
                    });
                });
        },

        // 读取文件

        loadFile(url) {
            var url = url;

            var htmlobj = axios({ url: url, async: false });
            console.log(htmlobj);

            var dataString = htmlobj.responseText;

            var data = eval("(" + dataString + ")"); //转换为json对象();

            var status = data["status"];

            var personScoreList = data["list"];
        },
        onClickLeft() {
            if (this.$route.query.num) {
                this.$router.push({
                    path: "card",
                    query: {
                        num: this.$route.query.num,
                        templateId: this.$route.query.templateId,
                    },
                });
            } else {
                this.$router.push({ path: "/" });
            }
        },
        sumbitForm() {
            var toCard = true;
            var arr = [];
            var optionId = [];
            if (this.$route.query.num) {
                this.$router.push({
                    path: "/card",
                    query: {
                        num: this.$route.query.num,
                        templateId: this.$route.query.templateId,
                    },
                });
                return false;
            }
            this.questionVoList.forEach((element) => {
                var flag = false;

                element.optionVoList.forEach((element2) => {
                    var item = {
                        answer: element.answer,
                        templateId: element.templateId,
                        questionType: element.type,
                        questionId: element.id,
                        optionId: element2.id,
                        code: this.$route.query.code,
                    };
                    if (element.type == 5) {
                        item.answer = this.form.resultImg;
                    }
                    if (element2.checked) {
                        item.answer = element2.name;
                        if (element2.aliasName)
                            item.aliasName = element2.aliasName;
                        flag = true;
                        arr.push(item);
                    }

                    if (element.type == 0 && element.answer == element2.id) {
                        if (element2.aliasName)
                            item.aliasName = element2.aliasName;
                    }
                    if (item.answer == element2.id) {
                        item.answer = element2.name;
                        arr.push(item);
                    }
                    if (element.type == 2 || element.type == 5) {
                        arr.push(item);
                    }
                });
                if (
                    element.type != 4 &&
                    element.type != 5 &&
                    element.isMandatory &&
                    element.isShow
                ) {
                    if (!element.answer) {
                        Toast("请将信息填写完整");
                        toCard = false;
                        return;
                    }
                    if (element.type == 1 && !flag) {
                        Toast("请将信息填写完整");
                        toCard = false;
                        return;
                    }
                }
            });
            console.log(this.partList);
            this.partList.forEach((element) => {
                if (toCard == false) {
                    return;
                }
                element.arr.forEach((element2) => {
                    var flag = false;
                    element2.optionVoList.forEach((element3) => {
                        var item = {
                            answer: element2.answer,
                            templateId: element2.templateId,
                            questionType: element2.type,
                            questionId: element2.id,
                            optionId: element3.id,
                            code: this.$route.query.code,
                        };
                        if (element2.type == 5) {
                            item.answer = this.form.resultImg;
                        }
                        if (element3.checked) {
                            item.answer = element3.name;
                            if (element3.aliasName)
                                item.aliasName = element3.aliasName;
                            flag = true;
                            arr.push(item);
                        }
                        if (
                            element2.type == 0 &&
                            element2.answer == element3.id
                        ) {
                            if (element3.aliasName)
                                item.aliasName = element3.aliasName;
                        }
                        if (item.answer == element3.id) {
                            item.answer = element3.name;
                            arr.push(item);
                        }
                        if (element2.type == 2 || element2.type == 5) {
                            arr.push(item);
                        }
                    });
                    if (
                        element2.type != 4 &&
                        element2.type != 5 &&
                        element2.isMandatory &&
                        element2.isShow
                    ) {
                        if (!element2.answer) {
                            Toast("请将信息填写完整");
                            toCard = false;
                            return;
                        }
                        if (element2.type == 1 && !flag) {
                            Toast("请将信息填写完整");
                            toCard = false;
                            return;
                        }
                    }
                });
            });
            if (toCard && this.showSubmit) {
                console.log(arr);
                if (this.submitFlag) {
                    this.submitFlag = false;
                    setTimeout(() => {
                        this.submitFlag = true;
                    }, 1000);
                    api.Question.addExam(arr).then((res) => {
                        this.$router.push({
                            path: "/card",
                            query: {
                                num: res,
                                templateId: this.$route.query.templateId,
                            },
                        });
                    });
                }
            }
        },
        showSign() {
            this.show = true;
        },
        handleReset() {
            console.log(this.$refs.esign);
            this.$refs.esign.reset();
            this.lineWidth = "10";
        },
        handleGenerate() {
            this.$refs.esign
                .generate()
                .then((res) => {
                    this.form.resultImg = res;
                    console.log(this.form.resultImg);
                    // this.$emit('close', this.resultImg)
                    this.$refs.esign.reset();
                    this.show = false;
                })
                .catch((err) => {
                    Toast.fail("请签名");
                    // alert("") // 画布没有签字时会执行这里 'Not Signned'
                });
        },
    },
};
</script>

<style scoped>
.top {
    padding: 0.2rem;
}
.topBox {
    height: 1.48rem;
}
.topBox .left .img {
    width: 2.16rem;
    height: 1.48rem;
    background: #d8d8d8;
    border-radius: 0.08rem;
}
.topBox .right div img {
    width: 0.22rem;
    height: 0.28rem;
    margin-right: 0.2rem;
}
.bottomBox {
    padding: 0.4rem 0;
    font-size: 0.22rem;
    color: #666666;
}
.middle {
    background: #f8f8f8;
    padding-top: 3.2rem;
    width: 7.5rem;
}
.middle .content {
    margin: 0rem 0.2rem 0 0.2rem;
    background: #ffffff;
}
.middle .content .twoSelect {
    border-bottom: 0.01rem solid #eeeeee;
    padding: 0.1rem 0;
    display: flex;
    justify-content: space-around;
}
.twoSelect label {
    margin: 0;
}
.right .hosName {
    font-size: 0.28rem;
    font-weight: 600;
    color: #000000;
    line-height: 0.4rem;
    letter-spacing: 1px;
}
.right .top_middle,
.right .address {
    font-size: 0.24rem;
    color: #666666;
}

.basicInfo {
    padding: 0 0.2rem;
}
.basicTitle {
    padding: 0.3rem 0;
    font-size: 0.28rem;
    font-weight: 600;
    color: #333333;
    letter-spacing: 1px;
}
/* .el-form-item{
  margin-bottom: 2px;
} */
.el-form-item {
    margin-bottom: 0;
}
.formBox .el-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.formBox .el-form-item .el-input {
    width: 6.7rem;
}
.formBox .el-radio {
    width: 2rem;
}
.otherPeople {
    color: #333333;
    font-size: 0.24rem;
}
.otherPeople span {
    color: #999999;
    font-size: 0.2rem;
}
.otherPeople div {
    margin-bottom: 0.1rem;
}
.manyPeople .desc {
    left: 1.7rem;
    top: 0.2rem;
    color: #999999;
}
.investInfo .el-radio {
    width: 2.6rem;
    font-size: 0.24rem;
}
.good {
    font-size: 0.24rem;
    color: #2dbd8d;
    margin-left: 0.1rem;
}
.bad {
    color: #e50a0a;
    font-size: 0.24rem;
    margin-left: 0.1rem;
}
.formBox {
    font-size: 0.24rem;
}
.investInfo .el-checkbox {
    width: 2.5rem;
}
.investInfo ul li div .time {
    color: #e75507;
}
.investInfo .problem .el-checkbox {
    width: 1.6rem;
}
.investInfo .danger .el-checkbox {
    width: 4rem;
}
.bottom_box {
    margin-top: 0.6rem;
}
.showBox {
    color: #999999;
    padding-top: 0.2rem;
    padding-left: 0.2rem;
    font-size: 0.24rem;
    width: 4rem;
    height: 1.2rem;
    margin: 0 auto;
    border: 0.01rem solid #cccccc;
}
.bottom_box .prom {
    text-align: left;
    font-size: 0.22rem;
    color: #e75507;
    padding-bottom: 0.3rem;
}

.belive {
    margin-top: 0.2rem;
}
.middle .lastBox {
    padding: 0.7rem 0;
    display: flex;
    justify-content: center;
}
.centerBox {
    width: 7.5rem;
}
.activeSumbit,
.sumbitBtn {
    border-radius: 0.1rem;
    border: none;
    margin: auto;
    font-size: 0.28rem;
    color: #ffffff;
    width: 7.1rem;
    height: 0.6rem;
}
.sumbitBtn {
    background: #999999;
}
.activeSumbit {
    background: #2dbd8d;
}
.popTop {
    padding: 0.3rem 0;
    font-size: 0.28rem;
    font-weight: 600;
    color: #333333;
    border-bottom: 0.01rem solid #eeeeee;
}
.popAll {
    padding: 0 0.2rem;
}
.signIn {
    margin-top: 0.2rem;
}
.popAll .btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.3rem 0;
}
.popAll .btn button {
    width: 3.2rem;
    height: 0.8rem;
}
.popAll .btn button:nth-child(1) {
    background: #e5e5e5;
    border-radius: 0.4rem;
    border: none;
    font-weight: 600;
    color: #333333;
    letter-spacing: 1px;
}
.popAll .btn button:nth-child(2) {
    background: #2dbd8d;
    box-shadow: 0rem 0.02rem 0.1rem 0rem rgba(45, 189, 141, 0.5);
    border-radius: 0.4rem;
    border: none;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 1px;
}
.el-input__inner:focus {
    background-color: #2dbd8d !important;
}
.van-dialog__header--isolated {
    font-size: 0.28rem;
    color: #e75507 !important;
}
li .showBox1 {
    padding: 0rem;
    position: relative;
}
li .showBox1 img {
    position: absolute;
    top: 0.5rem;
    left: 0.2rem;
}
</style>