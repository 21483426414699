import request from '@/api/axios'; // 导入http中创建的axios实例

const Question = {
    //获取问卷模板详情
    questionDetail(id) {
        return request({
            url: '/api/open/mobile/questionnaire/template/' + id,
            method: 'get'
        })
    },

    //新增答卷
    addExam(data) {
        return request({
            url: '/api/open/mobile/questionnaire/answer',
            method: 'post',
            data
        })
    },

    //查询用户填写的数据处理最后二维码的结果
    getQrCodeState(data) {
        return request({
            url: '/api/open/mobile/questionnaire/answer/getQrCodeState/'+ data,
            method: 'get',
        })
    },

    //申报记录查询
    list(data) {
        return request({
            url: '/api/open/mobile/questionnaire/answer/declareRecord?answer='+data,
            method: 'get',
        })
    },

    //获取答卷详情
    answer(code) {
        return request({
            url: '/api/open/mobile/questionnaire/answer/getInfoByCode/' + code,
            method: 'get',
        })
    },
    //预览模板详情
    getPreviewByiId(id) {
        return request({
            url: '/api/open/mobile/questionnaire/template/getPreviewById/' + id,
            method: 'get',
        })
    },


}


export default Question;
