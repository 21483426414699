<template>
  <van-nav-bar
    title="项目详情"
    left-text="返回"
    left-arrow
    @click-left="onClickLeft"
    v-if="this.$route.query.type != 2"
  />
  <div class="all bg_white">
    <div class="top_box pt18 pb18 pr22 pl22">
      <DetailTitle :ProjectInfo="ProjectInfo"></DetailTitle>
    </div>
    <div>
      <div class="bottom_box pt15">
        <div class="CellNum mr22 ml22 fs14 fw600 cr_b99 lh20 f_ls_1">
          共{{ ProjectInfo.sum }}项可检查的项目
        </div>
        <CellList :CellList="ProjectInfo.resultVo"></CellList>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar } from "vant";
import api from "@/api/api/Index";
import TcList from "@/components/content/MealList.vue";
import TcType from "@/components/content/MealType.vue";
import DetailTitle from "@/components/content/DetailTitle.vue";
import CellList from "@/components/content/CellList.vue";
export default {
  components: {
    vanNavBar: NavBar,
    TcType,
    TcList,
    DetailTitle,
    CellList,
  },
  created() {
    //套餐信息
    var _this = this;
    api.Examinator.dictType("inspection_item_type").then((res2) => {
      if (this.$route.query.type != 2) {
        api.ProjectDetail.mealDetail(this.$route.query.id).then((res) => {
          var packageList = res[0];
          var sum = 0;
          packageList.resultVo.forEach((item, index) => {
            sum += item.itemVoList.length;
          });
          packageList.sum = sum;
          for (let index = 0; index < packageList.resultVo.length; index++) {
            res2.forEach((element) => {
              if (
                packageList.resultVo[index].inspectionItemType ==
                element.dictValue
              ) {
                packageList.resultVo[index].inspectionItemType =
                  element.dictLabel;
              }
            });
          }
          _this.ProjectInfo = packageList;
        });
      } else {
        api.itemCheck
          .selectByReserveRegisterNo(this.$route.query.id)
          .then((res) => {
            var packageList = res[0];
            var sum = 0;
            packageList.resultVo.forEach((item, index) => {
              sum += item.itemVoList.length;
            });
            packageList.sum = sum;
            for (let index = 0; index < packageList.resultVo.length; index++) {
              res2.forEach((element) => {
                if (
                  packageList.resultVo[index].inspectionItemType ==
                  element.dictValue
                ) {
                  packageList.resultVo[index].inspectionItemType =
                    element.dictLabel;
                }
              });
            }
            _this.ProjectInfo = packageList;
          });
      }
    });
  },
  data() {
    return {
      ProjectInfo: {}, //套餐信息
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return { onClickLeft };
  },
};
</script>

<style scoped>
.all {
  width: 100%;
  min-height: 90vh;
}
.bottom_box {
  border-top: 0.2rem solid #f8f8f8;
}
</style>
