<template>
  <div class="top">
    <div class=" pb20">
      <van-nav-bar title="个人中心" left-arrow fixed>
        <template #left> </template>
      </van-nav-bar>
      <van-tabbar v-model="isActive">
        <van-tabbar-item>
          <router-link to="/">
            <div><img :src="icon.active" /></div>
            <div class="fs12 fw600 cr_999 lh14 mt2 mb2">主页</div>
          </router-link>
        </van-tabbar-item>
        <van-tabbar-item>
          <div><img :src="icon.inactive" /></div>
          <div class="fs12 fw600 cr_org lh14 mt2 mb2">我的</div>
        </van-tabbar-item>
      </van-tabbar>
      <!-- 头部 -->
      <div class="info flex_r f_ai_c pd20 pt68">
        <div class="right">
          <img
            class="brs_50 br_1_white mr14"
            src="@/assets/img/home/user.png"
            alt=""
          />
        </div>
        <div>
          <div class="fs23 fw600 cr_000 lh32 t_ls1">
            {{ userDetail.telephone }}
          </div>
          <!-- <div class="phone">{{ userDetail.telephone }}</div> -->
        </div>
      </div>
      <div class="bottom flex_r f_ai_c" style="visibility: hidden">
        <div class="flex_c f_ai_c mr48">
          <img src="@/assets/img/点中的爱心@2x.png" alt="" />
          <div>我的收藏</div>
        </div>
        <div class="flex_c f_ai_c">
          <img src="@/assets/img/liulan.png" alt="" />
          <div>浏览记录</div>
        </div>
      </div>
    </div>
    <!-- 我的订单 -->
    <div class="myOrder mr23 ml23 br_b_1_grey pb23">
      <div class="up f_ai_c flex_r f_jc_b" @click="toPage('OrderList')">
        <div class="fs16 fw600 cr_000 t_ls1">我的订单</div>
        <div class="fs12 fw600 cr_999 lh16 t_ls1">
          查看全部<van-icon name="arrow" />
        </div>
      </div>
      <ul class="flex_r f_jc_b mt19 ml8 mr8">
        <li
          class="flex_c f_jc_c f_ai_c"
          v-for="(item, index) in OrderList"
          :key="index"
          @click="toPage('OrderList', index)"
        >
          <div class="img posr" style="width: 0.48rem; height: 0.48rem">
            <div class="num bg_org brs_8 cr_fff tc lh15" v-if="item.num != 0">
              {{ item.num }}
            </div>
            <img :src="item.img" alt="" />
          </div>
          <div class="fs12 fw600 cr_000 lh16 t_ls1 mt8">{{ item.name }}</div>
        </li>
      </ul>
    </div>
    <!-- 体检人跟报告 -->
    <div class="laster ml23 mr23">
      <router-link to="/Examinator">
        <div class="flex_r f_jc_b f_ai_c">
          <div class="flex_r f_ai_c">
            <img class="mr12" src="@/assets/img/icon/message.png" alt="" />
            <div class="fs14 fw600 cr_000 lh50 t_ls1">体检人信息</div>
          </div>
          <div class="right">
            <van-icon name="arrow" />
          </div>
        </div>
      </router-link>
      <!-- <router-link to="/ItemCheck">
        <div class="flex_r f_jc_b f_ai_c">
          <div class="flex_r f_ai_c">
            <img class="mr12" src="@/assets/img/icon/1.png" alt="" />
            <div class="fs14 fw600 cr_000 lh50 t_ls1">查询报告</div>
          </div>
          <div class="right">
            <van-icon name="arrow" />
          </div>
        </div>
      </router-link> -->
    </div>
    <div class="exit_btn">
      <button
        class="bg_f8 brs_20 br_1_e9 fs14 cr_000 lh20 t_ls1 mg23 mt10"
        @click="logout()"
      >
        退出登录
      </button>
    </div>
  </div>
</template>

<script>
import "vant/lib/index.css";
import { ref } from "vue";
import api from "@/api/api/Index";
import { Tabbar, TabbarItem, Popup, NavBar } from "vant";
import { useStore } from "vuex";
export default {
  components: {
    vanPopup: Popup,
    vanTabbar: Tabbar,
    vanNavBar: NavBar,
    vanTabbarItem: TabbarItem,
  },
  setup() {
    const active = ref(1);
    const store = new useStore();
    return {
      active,
      store,
    };
  },
  created() {
    var _this = this;
    //获取用户信息
    api.Login.getCurrentUser().then((res) => {
      _this.userDetail = res;
    });
    api.Login.getCurrentUserOrder().then((res) => {
      console.log(res)
      _this.OrderList[0].num = res.awaitPay
      _this.OrderList[2].num = res.awaitPhysicalExam
      _this.OrderList[1].num = res.awaitSubscribe
      _this.OrderList[3].num = res.refund
    });
  },
  methods: {
    toPage(page, type) {
      this.$router.push({
        name: page,
        query: { type: type },
      });
    },
    //退出登录
    logout() {
      var _this = this;
      api.Login.logout().then((res) => {
        _this.store.state.token = null;
        localStorage.removeItem("token");
        _this.$router.replace({
          path: "/",
        });
      });
    },
  },
  data() {
    return {
      icon: {
        active: require("@/assets/img/home/nohome.png"),
        inactive: require("@/assets/img/home/nouser.png"),
      },
      userDetail: {}, //用户信息
      OrderList: [
        {
          name: "待付款",
          img: require("@/assets/img/icon/user_menu_1.png"),
          num: 0,
        },
        {
          name: "待预约",
          img: require("@/assets/img/icon/user_menu_2.png"),
          num: 0,
        },
        {
          name: "待体检",
          img: require("@/assets/img/icon/user_menu_3.png"),
          num: 0,
        },
        {
          name: "退款",
          img: require("@/assets/img/icon/user_menu_4.png"),
          num: 0,
        },
      ],
    };
  },
};
</script>

<style scoped>
.top {
  background: no-repeat;
  background-image: url("../../assets/img/user_bg.png");
  background-size: 100% 5rem;
}

.van-nav-bar {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
}

.info img {
  width: 1.42rem;
  height: 1.42rem;
}

.bottom img {
  width: 0.3rem;
  height: 0.3rem;
}

.myOrder .num {
  width: 0.3rem;
  height: 0.3rem;
  position: absolute;
  z-index: 1;
  right: -0.2rem;
  top: -0.15rem;
}

.laster img {
  width: 0.28rem;
}

.exit_btn button {
  width: 6.54rem;
  height: 0.8rem;
}

.van-tabbar-item {
  width: 1em;
  display: flex;
  flex-direction: column;
}

.van-tabbar-item img {
  width: 0.4rem;
  height: 0.4rem;
  margin: 0 auto;
}
</style>
