import {
    createRouter,
    createWebHistory
} from 'vue-router'
import store from '../store'
import Home from '@/views/Home.vue'
import ProjectDetail from '@/views/Booking/ProjectDetail.vue'
import Order from '@/views/Booking/Order.vue'
import OrderStatus from '@/views/Booking/OrderStatus.vue'
import Detail from '@/views/Common/Detail.vue'
import OrderList from '@/views/User/OrderList.vue'
import OrderDetail from '@/views/User/OrderDetail.vue'
import User from '@/views/User/User.vue'
import Goods from '@/views/User/Goods.vue'
import Examinator from '@/views/User/Examinator.vue'
import AddExaminator from '@/views/User/AddExaminator.vue'
import Login from '@/views/Common/Login.vue'
import Map from '@/views/Common/Map.vue'
import Introduction from '@/views/Index/Introduction.vue'
import Inform from '@/views/Index/Inform.vue'
import ProjectList from '@/views/Index/ProjectList.vue'
import SelectReport from '@/views/CheckupReport/SelectReport.vue'
import ReportList from '@/views/CheckupReport/ReportList.vue'
import Report from '@/views/CheckupReport/Report.vue'
//团检
import ItemCheck from '@/views/ItemCheck/ItemCheck.vue'
import CreatedCheck from '@/views/ItemCheck/CreatedCheck.vue'
import OtherCheck from '@/views/ItemCheck/OtherCheck.vue'
import ItemDetail from '@/views/ItemCheck/ItemDetail.vue'
import ItemStatus from '@/views/ItemCheck/ItemStatus.vue'

//调查问卷
import Question from '@/views/Investigation/Question.vue'
import Card from '@/views/Investigation/Card.vue'
import Record from '@/views/Investigation/Record.vue'


const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
// 团检
{
    path: '/CreatedCheck',
    name: 'CreatedCheck',
    component: CreatedCheck
},
{
    path: '/ItemStatus',
    name: 'ItemStatus',
    component: ItemStatus
},
{
    path: '/ItemDetail',
    name: 'ItemDetail',
    component: ItemDetail
},
{
    path: '/OtherCheck',
    name: 'OtherCheck',
    component: OtherCheck
},
{
    path: '/ItemCheck',
    name: 'ItemCheck',
    component: ItemCheck
},

{
    path: '/Login',
    name: 'Login',
    component: Login
},
{
    path: '/Map',
    name: 'Map',
    component: Map
},
{
    path: '/OrderStatus',
    name: 'OrderStatus',
    component: OrderStatus
},
{
    path: '/Detail',
    name: 'Detail',
    component: Detail
},
{
    path: '/ProjectList',
    name: 'ProjectList',
    component: ProjectList
},
{
    path: '/Inform',
    name: 'Inform',
    component: Inform
},
{
    path: '/Introduction',
    name: 'Introduction',
    component: Introduction
},
{
    path: '/ProjectDetail',
    name: 'ProjectDetail',
    component: ProjectDetail
},
{
    path: '/Examinator',
    name: 'Examinator',
    component: Examinator
},
{
    path: '/AddExaminator',
    name: 'AddExaminator',
    component: AddExaminator
},
{
    path: '/OrderDetail',
    name: 'OrderDetail',
    component: OrderDetail
},
{
    path: '/Order',
    name: 'Order',
    component: Order
},
{
    path: '/User',
    name: 'User',
    component: User
},
{
    path: '/Goods',
    name: 'Goods',
    component: Goods
},
{
    path: '/OrderList',
    name: 'OrderList',
    component: OrderList
},
{
    path: '/ReportList',
    name: 'ReportList',
    component: ReportList
},
{
    path: '/SelectReport',
    name: 'SelectReport',
    component: SelectReport
},
{
    path: '/Report',
    name: 'Report',
    component: Report
},
//调查问卷
{
    path: '/Question',
    name: 'Question',
    component: Question,
    // children: [
    //     {

    //         path: '/Login',
    //         name: 'Login',
    //         component: Login,
    //         beforeEach:(to,from,next)=>{
    //             if(localStorage.getItem('token')){
    //                 next()
    //             }
    //         }

    //     }
    // ]
},
{
    path: '/Card',
    name: 'Card',
    component: Card
},
{
    path: '/Record',
    name: 'Record',
    component: Record
},

]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {
    
    if (to.meta.title) {
        document.title = to.meta.title

    } else {
        document.title = "衡阳市中心医院健康管理（体检）中心"
    }
    next()
})
//路由跳转页面滚动到顶部
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
    if (from.path == '/ItemStatus') {
        clearInterval(store.state.interval)
    }

});
export default router