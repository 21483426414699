<template>
  <van-nav-bar
    style="z-index: 100"
    title="申报记录"
    left-arrow
    @click-left="onClickLeft"
    fixed
    ><template #left>
      <i style="font-size: 0.4583rem; color: #2e2e2e"
        ><van-icon color="#000000" name="arrow-left"
      /></i>
    </template>
  </van-nav-bar>
  <div class="all pt46 posr">
    <div class="topBox bg_fff flex_r">
      <div @click="toOne('本人申报')" :class="type == '本人申报' ? 'type' : ''">
        本人申报
      </div>
      <div @click="toOne('代替申报')" :class="type == '代替申报' ? 'type' : ''">
        代替申报
      </div>
    </div>
    <div class="record_content">
      <ul>
        <li class="flex_r" v-for="(item, index) in recordList" :key="index" @click="toQuestion(item)">
          <div class="left"><img :src="item.imageUrl" alt="" /></div>
          <div class="right">
            <div class="record_top flex_r f_jc_b">
              <div class="top_name">{{ item.subMerchantName }}</div>
              <div class="top_time">{{ item.createTime }}</div>
            </div>
            <div>
              <div class="peopleName" style="padding-top: 0.4rem;" v-if="item.attachAnswer == null">
                申报人：<span>{{ item.myDeclare }}</span>
              </div>
              <div v-if="item.replaceDeclare" class="peopleName" style="padding-top: 0.1rem;">
                代申报人：<span>{{ item.replaceDeclare }}</span>
              </div>
            </div>
            
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { NavBar, ActionBar, Popup, ActionBarButton, Toast, Dialog } from "vant";
import api from "@/api/api/Index";
export default {
  components: {
    vanNavBar: NavBar,
    vanActionBar: ActionBar,
    vanActionBarButton: ActionBarButton,
    vanPopup: Popup,
    vanDialog: Dialog,
  },
  created() {
    this.getData();
  },
  methods: {
    toQuestion(item){
      this.$router.push({ path: "question",query:{num:item.code,templateId:this.$route.query.templateId}});
    },
    toOne(type) {
      this.type = type;
      this.getData();
    },
    getData() {
      api.Question.list(this.type).then((res) => {
        this.recordList = res.rows;
      });
    },
    onClickLeft() {
      this.$router.push({ path: "card",query:{num:this.$route.query.num,templateId:this.$route.query.templateId}});
    },
  },
  data() {
    return {
      type: "本人申报",
      recordList: [],
    };
  },
};
</script>

<style scoped>
.all {
  background-color: #f8f8f8;
  height: 100vh;
}
.top {
  position: absolute;
}
.topBox > div {
  width: 3.74rem;
  text-align: center;
  line-height: 0.6rem;
  margin: 0.1rem 0;
  font-size: 0.28rem;
}
.topBox > div:nth-child(1) {
  border-right: 0.01rem solid #eeeeee;
}
.type {
  color: #2dbd8d;
}
.record_content {
  margin-top: 0.2rem;
  background-color: white;
  padding: 0.1rem 0.4rem 0.48rem 0.4rem;
}
.record_content ul li .left {
  width: 1.5rem;
  height: 1.3rem;
  background: #d8d8d8;
  border-radius: 0.08rem;
}
.record_content ul li .right {
  padding-left: 0.2rem;
  width: 5.4rem;
}

.record_top .top_name {
  font-size: 0.28rem;
  font-weight: 600;
  color: #333333;
}
.record_top .top_time {
  font-size: 0.24rem;
  font-weight: 400;
  color: #999999;
}
.right .peopleName {
  font-size: 0.24rem;
  font-weight: 400;
  color: #999999;
}
.right .peopleName span {
  color: #333333;
}
.record_content ul li {
  padding: 0.2rem 0;
  border-bottom: 0.01rem solid #eeeeee;
}
</style>